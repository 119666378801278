<template>
  <div class="edit_box  mrb20">
    <div class="size18  mrb20">角色组</div>
    <div class="flex">
      <div class="mrr20 left_box ">
        <div class="left_box_item flexcc  size16" style="background-color: #66b1ff;color: aliceblue;">商品管理员</div>
        <div class="flexcc size16 left_box_item" v-for="(item,index) in roleData" :key="index">
          <el-button type="text" @click="searchClick(item.id)">{{ item.name }}</el-button>
        </div>
      </div>
      <div class="right_box" style="width: 100%;">
        <el-tree :data="dataList" :default-checked-keys="checkedData" show-checkbox default-expand-all node-key="id" @check="checkBoxFn()" ref="tree" highlight-current :props="defaultProps">
        </el-tree>
        <el-button type="primary" @click="save()">保 存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      role_id: "",
      rolePermission: [],
      nodeSelectedId: "", //默认选中的id
      defaultProps: {
        children: "children",
        label: "title",
      },
      saveRolePermissionLoading: false,
      checkList: [],
      permissionConfig: [],
      dataList: [],
      checkedKeys: "",
      checkedData: [],
      roleData: [],
    };
  },

  created() {
    this.role_id = this.$route.query.id;
    this.getroleList();
    this.getRolePermission(this.role_id);
  },
  mounted() {},
  computed: {},

  methods: {
    getlist() {},
    async save() {
      const checkList = this.checkList.filter((item, index, array) => {
        console.log("array123", array);
        return array.indexOf(item) === index;
      });
      const data = await this.$Api.permissionseditPermissions({
        menu_ids: checkList,
        role_id: this.role_id,
      });
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      console.log("保存成功的值", data);
      this.$message.success("保存成功");
      this.getRolePermission(this.role_id);
    },
    async getRolePermission(id) {
      const { data } = await this.$Api.permissionsShopPermissions({
        role_id: id,
      });
      this.checkedData = [];
      // console.log("权限列表123", data);
      this.dataList = data;
      this.dataList.forEach((i) => {
        if (i.checked == 1) this.checkedData.push(i.id);
        i.children.forEach((item) => {
          if (item.checked == 1) this.checkedData.push(item.id);
        });
      });
      // console.log("勾选的值", this.checkedData);
    },
    async getroleList() {
      const { data } = await this.$Api.permissionsRole();
      this.roleData = data.list;
    },
    searchClick(id) {
      this.role_id = id;
      this.dataList = [];
      this.getRolePermission(this.role_id);
    },
    // 选择权限
    checkBoxFn(val, num) {
      // console.log("选中的值", val);
      let checknnum = this.$refs.tree.getCheckedNodes();
      console.log("checknnum", checknnum);
      this.checkList = checknnum.map((item) => item.id);
      console.log("checkList选中的所有id", this.checkList);
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-tree-node {
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;
}
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
  // width: 80%;
}
.left_box {
  height: 100%;
  border: 1px solid #66b1ff;
  .left_box_item {
    width: 150px;
    height: 38px;
    border-bottom: 1px solid #66b1ff;
  }
}
.left_box_item:last-child {
  border-bottom: none;
}
.right_box {
  padding: 20px;
  border: 1px solid #d3cccc;
}
</style>